<template>
  <v-container>
    <v-row>
      <v-col class="text-center visit-title-top" cols="12">
        내원 확인
        <span v-if="isView === 1">완료</span>
        <span v-if="isView === 2">만료</span>
      </v-col>
      <v-col cols="12" v-if="isView === 0">
        <v-row justify="center">
          <v-col class="visit-info" cols="11">
            <p>등록된 휴대폰 번호를 이용하여 내원체크를 진행합니다.</p>
            <p>입력하신 휴대폰 번호는 단순 내원 확인용으로 이용되며 이외의 용도에는 사용되지 않습니다.</p>
            <p>전화번호를 입력 후 확인 버튼을 누르시면 내원 확인이 완료됩니다.</p>
          </v-col>
          <v-col class="visit-content" cols="11">
            휴대폰 번호
          </v-col>
          <v-col cols="11">
            <v-text-field
              background-color="#f5f9fa"
              outlined
              rounded
              clearable
              placeholder="휴대폰 번호를 입력해주세요"
              class="visit-phone"
              v-model="form.phone.value"
              @keyup="checkPhone()"
              @blur="checkPhone()"
              maxlength="13"
            ></v-text-field>
            <p class="warn-message" v-if="form.phone.hasError">
              {{ form.phone.errorTxt }}
            </p>
          </v-col>
          <v-col cols="11" class="text-center">
            <v-btn
              class="visit-btn"
              depressed
              dark
              rounded
              height="60px"
              width="150px"
              @click="visit()"
              :class="
                {
                  visitBtnActive: 
                    this.form.phone.flag
                }
              "
            >
              확인
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="isView === 1">
        <v-row justify="center">
          <v-col class="visit-total visit-info" cols="10">
            <v-row>
              <v-col cols="12" class="visit-total-text text-center">
                내원확인 요청이 <b>완료</b>되었습니다.
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="isView === 2">
        <v-row justify="center">
          <v-col class="visit-total visit-info" cols="10">
            <v-row>
              <v-col cols="12" class="visit-total-text text-center">
                내원확인 요청이 <b>만료</b>되었습니다.
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </v-container>
  <!-- <div class="app-context mobile-layout">
    <div class="context mobile inQr">
      <directive :directiveInfo="directiveInfo"></directive>
      <div class="qr" v-if="isView === 0">
         <h4 class="title">안내사항</h4>
        <div class="notification">
          <p>등록된 휴대폰번호를 이용하여 내원체크를 진행합니다. 입력하신 전화번호는 단순 내원 확인용으로 이용되며 이외의 용도에는 사용되지 않습니다. 전화번호를 입력 후 확인 버튼을 누르시면 내원 확인이 완료됩니다.</p>
        </div>
        <div class="j-article first">
          <h5 class="title">전화번호</h5>
          <div class="input-wrap">
            <input
              type="text"
              placeholder="전화번호를 입력해주세요"
              v-model="form.phone.value"
              @keyup="checkPhone()"
              @blur="checkPhone()"
              maxlength="13"
            />
          </div>
          <p class="warn-message" v-if="form.phone.hasError">
            {{ form.phone.errorTxt }}
          </p>
          <div
            class="btn-wrap"
            :class="{
              active: this.form.phone.flag
            }"
          >
            <button type="button" @click="visit()">확인</button>
          </div>
          <a class="hidden" @click="winClose()">테스트</a>
        </div>
      </div>
      <div class="complete" v-if="isView === 1">
        <p>내원확인 요청이 <b>완료</b>되었습니다.</p>
      </div>
         <div class="complete" v-if="isView === 2">
        <p>내원확인 요청이 <b>만료</b>되었습니다.</p>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div> -->
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup";
import Directive from "@/components/join/Directive";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  components: {
    DefaultPopup,
    Directive
  },
  data() {
    return {
      popupSet: {},
      isView: 0,
      directiveInfo: {
        title: "내원 확인",
        isTitleNewLine: false,
        content: null,
        isContentNewLine: true,
        warn: null,
        isWarnNewLine: false,
        isMobile: true
      },
      form: {
        phone: {
          value: "",
          flag: false,
          hasError: false,
          errorTxt: "숫자만 입력해주세요"
        }
      }
    };
  },
  created() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  mounted() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "off",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  },
  computed: {
    ...mapState("basic", ["checkPopup"])
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapActions("request", ["VISIT_CHECK_UN_AUTH"]),
    async visit() {
      if (this.checkFlag()) {
        try {
          const payload = {
            referId: this.$route.params.code,
            hpNo: this.form.phone.value.replace(/-/gi, "")
          };

          //console.log("payload : ", payload);

          //유효성 검사
          //1. 진단의뢰번호
          if(payload.referId === null 
          || payload.referId === undefined) {
          // payload.referId = "";
          
          // var referIdBoo = /^[0-9]{8,14}$/;

          // if(!referIdBoo.test(payload.referId)){
            this.SET_POPUP(true);
              this.popupSet.title = "서버요청 실패";
              this.popupSet.content = "리스트를 불러올 수 없습니다.";
              this.popupSet.popType = "warn";
              this.popupSet.cancelBtnText = "확인";
            return;
          }

          await this.VISIT_CHECK_UN_AUTH(payload).then(() => {
            this.isView = 1;
            /**
            setTimeout(() => {
              this.SET_POPUP(false);
              document.querySelector("a.hidden").click();
            }, 800);
             */
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.isView = 2;
            this.SET_POPUP(true);
            this.popupSet.title = "내원확인 만료";
            this.popupSet.content = "내원확인이 이미 처리되었습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.confirmBtnText = "확인";
          }
          /**
          setTimeout(() => {
            this.SET_POPUP(false);
            document.querySelector("a.hidden").click();
          }, 800);
           */
        }
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "입력값 확인";
        this.popupSet.content = "전화번호를 점검해주세요";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    },
    checkFlag() {
      if (!this.form.phone.flag) {
        this.form.hasError = true;
        this.form.value = "";
        return false;
      }
      return true;
    },
    winClose() {
      window.open("", "_self", "");
      window.close();
    },
    checkPhone() {
      this.form.phone.flag = false;
      this.form.phone.hasError = true;
      const phone = this.form.phone.value.replace(/\s/gi, "");
      if (phone === "") {
        this.form.phone.flag = false;
        this.form.phone.hasError = true;
        this.form.phone.errorTxt = "휴대폰 번호를 입력해주세요.";
        return false;
      }
      const isPhone = /^[0-9|-]{10,13}$/;
      if (!isPhone.test(phone)) {
        this.form.phone.errorTxt = "숫자만 입력해주세요.";
        this.form.phone.flag = false;
        this.form.phone.hasError = true;
        return false;
      }
      this.form.phone.flag = true;
      this.form.phone.hasError = false;
      this.form.phone.errorTxt = "";
      return true;
    }
  },
  destroyed() {
    const sendAppheaderItem = {
      method: "appHeaderOff",
      item: "on",
    };
    this.$emit("send-app-header-method", sendAppheaderItem);
  }
};
</script>

<style lang="scss" scoped>
.app-context .context.mobile {
  padding: 0 !important;
  .directive {
    padding: 35px 0;
  }
  .complete {
    position: relative;
    padding: 35px 0 0 0;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      background: #146f83;
      top: 0;
    }
    p {
      font-family: "SUIT";
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
      b {
        font-weight: bold;
        color: #146f83;
      }
    }
  }
}

.qr {
  width: 100%;
  padding: 30px 0 0 0;
  margin: 0;
  position: relative;
  &:after {
    content: "";
    background: #146f83;
    width: 100%;
    height: 3px;
    top: 0px;
    position: absolute;
  }
  h4.title {
    margin: 0 auto 12.5px auto;
    width: calc(100% - 20px);
    font-family: "SUIT";
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 26px;
      letter-spacing: normal;
      text-align: left;
      color: #146f83;
  }
  .notification {
    width: calc(100% - 20px);
      margin: 0 auto 30px auto;
      padding: 8px 14px;
      position: relative;
      border: 1px solid #707070;
      border-radius: 6px;
    p {
      font-family: "SUIT";
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 24px;
      letter-spacing: normal;
      text-align: left;
      color: #707070;
    }
  }
  .a-action {
    display: flex;
    padding: 0 0 20px 0;
    border: 0px solid #707070;
    border-width: 0px 0px 1px 0px;
    align-items: center;
    cursor: pointer;
    .check {
      margin: 0 8px 0 0;
      img {
      }
    }
    h4 {
      font-family: "SUIT";
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 36px;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }
  }
  .article {
    margin: 30px 0;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      margin: 17.5px 0 0 0;
      &:first-of-type {
        margin: 0;
      }
      .i-slot {
        display: flex;
        .check {
          margin: 0 12px 0 0;
          display: flex;
          img {
          }
        }
        h4 {
          font-family: "SUIT";
          font-size: 24px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 36px;
          letter-spacing: normal;
          text-align: left;
          color: #000000;
        }
      }
      .btn-wrap {
        margin: 0;
        height: 55px;
        width: 110px;
        background: transparent;
        button {
          background: #146f83;
          &.default {
            background: #474747;
          }
        }
      }
    }
  }
  .btn-wrap {
    margin: 45px auto 50px auto;
    background: #e9e9e9;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    &.active {
      background: #146f83;
    }
    button {
      background: transparent;
      border: 0;
      font-family: "SUIT";
      font-size: 21px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 36px;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      width: 100%;
      height: inherit;
    }
  }
  .j-article {
    padding: 0 10px;
    &.first {
      margin: 0;
    }
    margin: 30px 0 0 0;
    h5.title {
      margin: 0 0 12.5px 0;
      font-family: "SUIT";
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 26px;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }
    .input-wrap {
      border: solid 1px #707070;
      position: relative;
      border-radius: 6px;
      input {
        border: 0;
        width: 100%;
        height: auto;
        background: transparent;
        font-family: "SUIT";
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 46px;
        letter-spacing: normal;
        text-align: left;
        color: #000000;
        padding: 0 12px;
      }
    }
    p.warn-message {
      margin: 20px 0 0 0;
      font-family: "SUIT";
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 25px;
      letter-spacing: normal;
      text-align: left;
      color: red;
    }
  }
}
a.hidden {
  display: none;
}
.select-place {
  height: auto;
  position: relative;
  .select-wrap {
    width: 100%;
    margin: 0;
    position: relative;
    .v-select {
      height: 48px;
    }
    p.warn-message {
      margin: 10px 0 0 0;
    }
  }
}

/////////////리뉴얼/////////////////////////

.visit-title-top {
  font-weight: 500;
  font-size: 34px;
  color: #146f83;
  margin: 50px 0px 50px 0px;
}

.visit-info {
  padding: 35px 30px 35px 30px;
  color: #146f83;
  background-color: #f5f9fa;
  border-radius: 20px;
  margin-bottom: 50px;

  p {
    font-size: 16px;
    word-break: keep-all;
  }

  .space {
    margin: 40px 0px 40px 0px;
  }
}

.visit-content {
    
  margin-top: 50px;;
  font-size: 30px;
  font-weight: 500;

  span {
    font-size: 20px;
    word-break: keep-all;
  }
}

.visit-phone {
  margin-top: 19px;
  font-size: 20px;
  font-weight: 400;
  position: relative;
  top: -20px;
}

.visit-btn {
  margin-top: 50px;
  margin-bottom: 100px;
  font-size: 25px;
}

.visitBtnActive {
  background-color: #146f83 !important;
}

p.warn-message {
  margin: 0px 0px 0px 20px;
  font-family: "SUIT";
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 25px;
  letter-spacing: normal;
  text-align: left;
  color: red;
  position: relative;
  top: -30px;
}

.visit-total {

  .visit-total-text {
    font-size: 30px;
    font-weight: 500;
    color: black;
  }

  .visit-sub-text {
    font-size: 24px;
    font-weight: 400;
  }


}

@media screen and (max-width: 700px) {
    .visit-total-text {
      font-size: 24px !important;
    }
  }

  @media screen and (max-width: 530px) {
    .visit-total-text {
      font-size: 22px !important;
    }
  }

  @media screen and (max-width: 440px) {
    .visit-total-text {
      font-size: 20px !important;
    }
  }

  @media screen and (max-width: 380px) {
    .visit-total-text {
      font-size: 16px !important;
    }
    .visit-title-top {
      font-size: 30px;
    }
  }

</style>
